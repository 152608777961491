.ql-editor {
    tab-size: 2;
    -moz-tab-size: 2;
    -o-tab-size: 2;
}

.ql-editor > ul {
    padding-left: 0.5em;
}

.ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0.5em;
}